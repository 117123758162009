<template>
  <div>
    <div class="mod-ipl mod-ipl-2022">
      <div class="mod-banner" style="height: 461.205px">
        <!--焦点图-->
        <!--end 焦点图-->
      </div>
      <div class="home-promo">
        <div class="">
          <h4>ALL PROMOTIONS</h4>
          <!-- <div class="promo-wrap slick-initialized slick-slider" id="ipl_promo">
            <div class="slick-list draggable">
              <div
                class="slick-track"
                style="
                  opacity: 1;
                  width: 12175px;
                  transform: translate3d(-5357px, 0px, 0px);
                "
              >
                <div
                  class="slick-slide slick-cloned"
                  data-slick-index="-3"
                  aria-hidden="true"
                  style="width: 457px"
                  tabindex="-1"
                >
                  <a
                    href="/in/promotions/index#980026282891169792"
                    tabindex="-1"
                    ><div class="promo-img">
                      <img
                        src="https://static-web.8ar7dbcwtr.com/knor6u/iimg/i20220505d571fea0ee4e7babb5b2c291535d58.jpg"
                      />
                    </div>
                    <div class="promo-info">KNOW MORE</div></a
                  >
                </div>
                <div
                  class="slick-slide slick-cloned"
                  data-slick-index="-2"
                  aria-hidden="true"
                  style="width: 457px"
                  tabindex="-1"
                >
                  <a
                    href="/in/promotions/index#980045075604463616"
                    tabindex="-1"
                    ><div class="promo-img">
                      <img
                        src="https://static-web.8ar7dbcwtr.com/knor6u/iimg/i202205d2e8e0f8360146c0a7d5511ee6481be4.jpg"
                      />
                    </div>
                    <div class="promo-info">KNOW MORE</div></a
                  >
                </div>
                <div
                  class="slick-slide slick-cloned"
                  data-slick-index="-1"
                  aria-hidden="true"
                  style="width: 457px"
                  tabindex="-1"
                >
                  <a
                    href="/in/promotions/index#980045969691660288"
                    tabindex="-1"
                    ><div class="promo-img">
                      <img
                        src="https://static-web.8ar7dbcwtr.com/knor6u/iimg/i202205a05f38a3dcfa40fc80e9150044ee9a21.jpg"
                      />
                    </div>
                    <div class="promo-info">KNOW MORE</div></a
                  >
                </div>
                <div
                  class="slick-slide"
                  data-slick-index="0"
                  aria-hidden="true"
                  style="width: 457px"
                  tabindex="-1"
                >
                  <a
                    href="/in/promotions/index#979655604186800128"
                    tabindex="-1"
                    ><div class="promo-img">
                      <img
                        src="https://static-web.8ar7dbcwtr.com/knor6u/iimg/i202205401e0c90f5634ee7b9c2acff09ba1337.jpg"
                      />
                    </div>
                    <div class="promo-info">KNOW MORE</div></a
                  >
                </div>
                <div
                  class="slick-slide"
                  data-slick-index="1"
                  aria-hidden="true"
                  style="width: 457px"
                  tabindex="-1"
                >
                  <a
                    href="/in/promotions/index#980039903020998656"
                    tabindex="-1"
                    ><div class="promo-img">
                      <img
                        src="https://static-web.8ar7dbcwtr.com/knor6u/iimg/i2022057b9a43475e064113a2e17738c6985b69.jpg"
                      />
                    </div>
                    <div class="promo-info">KNOW MORE</div></a
                  >
                </div>
                <div
                  class="slick-slide"
                  data-slick-index="2"
                  aria-hidden="true"
                  style="width: 457px"
                  tabindex="-1"
                >
                  <a
                    href="/in/promotions/index#979653878893400064"
                    tabindex="-1"
                    ><div class="promo-img">
                      <img
                        src="https://static-web.8ar7dbcwtr.com/knor6u/iimg/i202205d5a0766eb59749f6a46bcf507064896b.jpg"
                      />
                    </div>
                    <div class="promo-info">KNOW MORE</div></a
                  >
                </div>
                <div
                  class="slick-slide"
                  data-slick-index="3"
                  aria-hidden="true"
                  style="width: 457px"
                  tabindex="-1"
                >
                  <a
                    href="/in/promotions/index#980018504982224896"
                    tabindex="-1"
                    ><div class="promo-img">
                      <img
                        src="https://static-web.8ar7dbcwtr.com/knor6u/iimg/i20220552d8357181c54d1496d32c7c01a77eea.jpg"
                      />
                    </div>
                    <div class="promo-info">KNOW MORE</div></a
                  >
                </div>
                <div
                  class="slick-slide"
                  data-slick-index="4"
                  aria-hidden="true"
                  style="width: 457px"
                  tabindex="-1"
                >
                  <a
                    href="/in/promotions/index#980020850084700160"
                    tabindex="-1"
                    ><div class="promo-img">
                      <img
                        src="https://static-web.8ar7dbcwtr.com/knor6u/iimg/i2022052f52d49d2df145a4aaa7d056ab2fc525.jpg"
                      />
                    </div>
                    <div class="promo-info">KNOW MORE</div></a
                  >
                </div>
                <div
                  class="slick-slide"
                  data-slick-index="5"
                  aria-hidden="true"
                  style="width: 457px"
                  tabindex="-1"
                >
                  <a
                    href="/in/promotions/index#980036410348556288"
                    tabindex="-1"
                    ><div class="promo-img">
                      <img
                        src="https://static-web.8ar7dbcwtr.com/knor6u/iimg/i2022059e7c50cf71c74c959b55fbfe82e65673.jpg"
                      />
                    </div>
                    <div class="promo-info">KNOW MORE</div></a
                  >
                </div>
                <div
                  class="slick-slide"
                  data-slick-index="6"
                  aria-hidden="true"
                  style="width: 457px"
                  tabindex="-1"
                >
                  <a
                    href="/in/promotions/index#979660067173715968"
                    tabindex="-1"
                    ><div class="promo-img">
                      <img
                        src="https://static-web.8ar7dbcwtr.com/knor6u/iimg/i202205342cf510ba584540a208aed0db0093e2.jpg"
                      />
                    </div>
                    <div class="promo-info">KNOW MORE</div></a
                  >
                </div>
                <div
                  class="slick-slide"
                  data-slick-index="7"
                  aria-hidden="true"
                  style="width: 457px"
                  tabindex="-1"
                >
                  <a
                    href="/in/promotions/index#980024640422694912"
                    tabindex="-1"
                    ><div class="promo-img">
                      <img
                        src="https://static-web.8ar7dbcwtr.com/knor6u/iimg/i202205116aae25827044da8af3606827bc9918.jpg"
                      />
                    </div>
                    <div class="promo-info">KNOW MORE</div></a
                  >
                </div>
                <div
                  class="slick-slide slick-current slick-active"
                  data-slick-index="8"
                  aria-hidden="false"
                  style="width: 457px"
                  tabindex="0"
                >
                  <a href="/in/promotions/index#980026282891169792" tabindex="0"
                    ><div class="promo-img">
                      <img
                        src="https://static-web.8ar7dbcwtr.com/knor6u/iimg/i20220505d571fea0ee4e7babb5b2c291535d58.jpg"
                      />
                    </div>
                    <div class="promo-info">KNOW MORE</div></a
                  >
                </div>
                <div
                  class="slick-slide slick-active"
                  data-slick-index="9"
                  aria-hidden="false"
                  style="width: 457px"
                  tabindex="0"
                >
                  <a href="/in/promotions/index#980045075604463616" tabindex="0"
                    ><div class="promo-img">
                      <img
                        src="https://static-web.8ar7dbcwtr.com/knor6u/iimg/i202205d2e8e0f8360146c0a7d5511ee6481be4.jpg"
                      />
                    </div>
                    <div class="promo-info">KNOW MORE</div></a
                  >
                </div>
                <div
                  class="slick-slide slick-active"
                  data-slick-index="10"
                  aria-hidden="false"
                  style="width: 457px"
                  tabindex="0"
                >
                  <a href="/in/promotions/index#980045969691660288" tabindex="0"
                    ><div class="promo-img">
                      <img
                        src="https://static-web.8ar7dbcwtr.com/knor6u/iimg/i202205a05f38a3dcfa40fc80e9150044ee9a21.jpg"
                      />
                    </div>
                    <div class="promo-info">KNOW MORE</div></a
                  >
                </div>
                <div
                  class="slick-slide slick-cloned"
                  data-slick-index="11"
                  aria-hidden="true"
                  style="width: 457px"
                  tabindex="-1"
                >
                  <a
                    href="/in/promotions/index#979655604186800128"
                    tabindex="-1"
                    ><div class="promo-img">
                      <img
                        src="https://static-web.8ar7dbcwtr.com/knor6u/iimg/i202205401e0c90f5634ee7b9c2acff09ba1337.jpg"
                      />
                    </div>
                    <div class="promo-info">KNOW MORE</div></a
                  >
                </div>
                <div
                  class="slick-slide slick-cloned"
                  data-slick-index="12"
                  aria-hidden="true"
                  style="width: 457px"
                  tabindex="-1"
                >
                  <a
                    href="/in/promotions/index#980039903020998656"
                    tabindex="-1"
                    ><div class="promo-img">
                      <img
                        src="https://static-web.8ar7dbcwtr.com/knor6u/iimg/i2022057b9a43475e064113a2e17738c6985b69.jpg"
                      />
                    </div>
                    <div class="promo-info">KNOW MORE</div></a
                  >
                </div>
                <div
                  class="slick-slide slick-cloned"
                  data-slick-index="13"
                  aria-hidden="true"
                  style="width: 457px"
                  tabindex="-1"
                >
                  <a
                    href="/in/promotions/index#979653878893400064"
                    tabindex="-1"
                    ><div class="promo-img">
                      <img
                        src="https://static-web.8ar7dbcwtr.com/knor6u/iimg/i202205d5a0766eb59749f6a46bcf507064896b.jpg"
                      />
                    </div>
                    <div class="promo-info">KNOW MORE</div></a
                  >
                </div>
                <div
                  class="slick-slide slick-cloned"
                  data-slick-index="14"
                  aria-hidden="true"
                  style="width: 457px"
                  tabindex="-1"
                >
                  <a
                    href="/in/promotions/index#980018504982224896"
                    tabindex="-1"
                    ><div class="promo-img">
                      <img
                        src="https://static-web.8ar7dbcwtr.com/knor6u/iimg/i20220552d8357181c54d1496d32c7c01a77eea.jpg"
                      />
                    </div>
                    <div class="promo-info">KNOW MORE</div></a
                  >
                </div>
                <div
                  class="slick-slide slick-cloned"
                  data-slick-index="15"
                  aria-hidden="true"
                  style="width: 457px"
                  tabindex="-1"
                >
                  <a
                    href="/in/promotions/index#980020850084700160"
                    tabindex="-1"
                    ><div class="promo-img">
                      <img
                        src="https://static-web.8ar7dbcwtr.com/knor6u/iimg/i2022052f52d49d2df145a4aaa7d056ab2fc525.jpg"
                      />
                    </div>
                    <div class="promo-info">KNOW MORE</div></a
                  >
                </div>
                <div
                  class="slick-slide slick-cloned"
                  data-slick-index="16"
                  aria-hidden="true"
                  style="width: 457px"
                  tabindex="-1"
                >
                  <a
                    href="/in/promotions/index#980036410348556288"
                    tabindex="-1"
                    ><div class="promo-img">
                      <img
                        src="https://static-web.8ar7dbcwtr.com/knor6u/iimg/i2022059e7c50cf71c74c959b55fbfe82e65673.jpg"
                      />
                    </div>
                    <div class="promo-info">KNOW MORE</div></a
                  >
                </div>
                <div
                  class="slick-slide slick-cloned"
                  data-slick-index="17"
                  aria-hidden="true"
                  style="width: 457px"
                  tabindex="-1"
                >
                  <a
                    href="/in/promotions/index#979660067173715968"
                    tabindex="-1"
                    ><div class="promo-img">
                      <img
                        src="https://static-web.8ar7dbcwtr.com/knor6u/iimg/i202205342cf510ba584540a208aed0db0093e2.jpg"
                      />
                    </div>
                    <div class="promo-info">KNOW MORE</div></a
                  >
                </div>
                <div
                  class="slick-slide slick-cloned"
                  data-slick-index="18"
                  aria-hidden="true"
                  style="width: 457px"
                  tabindex="-1"
                >
                  <a
                    href="/in/promotions/index#980024640422694912"
                    tabindex="-1"
                    ><div class="promo-img">
                      <img
                        src="https://static-web.8ar7dbcwtr.com/knor6u/iimg/i202205116aae25827044da8af3606827bc9918.jpg"
                      />
                    </div>
                    <div class="promo-info">KNOW MORE</div></a
                  >
                </div>
                <div
                  class="slick-slide slick-cloned"
                  data-slick-index="19"
                  aria-hidden="true"
                  style="width: 457px"
                  tabindex="-1"
                >
                  <a
                    href="/in/promotions/index#980026282891169792"
                    tabindex="-1"
                    ><div class="promo-img">
                      <img
                        src="https://static-web.8ar7dbcwtr.com/knor6u/iimg/i20220505d571fea0ee4e7babb5b2c291535d58.jpg"
                      />
                    </div>
                    <div class="promo-info">KNOW MORE</div></a
                  >
                </div>
                <div
                  class="slick-slide slick-cloned"
                  data-slick-index="20"
                  aria-hidden="true"
                  style="width: 457px"
                  tabindex="-1"
                >
                  <a
                    href="/in/promotions/index#980045075604463616"
                    tabindex="-1"
                    ><div class="promo-img">
                      <img
                        src="https://static-web.8ar7dbcwtr.com/knor6u/iimg/i202205d2e8e0f8360146c0a7d5511ee6481be4.jpg"
                      />
                    </div>
                    <div class="promo-info">KNOW MORE</div></a
                  >
                </div>
                <div
                  class="slick-slide slick-cloned"
                  data-slick-index="21"
                  aria-hidden="true"
                  style="width: 457px"
                  tabindex="-1"
                >
                  <a
                    href="/in/promotions/index#980045969691660288"
                    tabindex="-1"
                    ><div class="promo-img">
                      <img
                        src="https://static-web.8ar7dbcwtr.com/knor6u/iimg/i202205a05f38a3dcfa40fc80e9150044ee9a21.jpg"
                      />
                    </div>
                    <div class="promo-info">KNOW MORE</div></a
                  >
                </div>
              </div>
            </div>
          </div> -->
          <!--<div class="btn-wrap" id="scrollbox-btn">
				</div>-->
        </div>
      </div>
      <div class="ipl-whatsapp">
        <h5>Submit your whatsapp number to get IPL 2022 schedule</h5>
        <div>
          <div class="iti iti--separate-dial-code">
            <div class="iti__flag-container">
              <div
                class="iti__selected-flag"
                role="combobox"
                aria-owns="country-listbox"
                title="India: +91"
              >
                <div class="iti__flag iti__in"></div>
                <div class="iti__selected-dial-code">+91</div>
              </div>
            </div>
            <input
              id="whatsapp_number"
              name="whatsapp_number"
              type="text"
              placeholder="WhatsApp Number"
              autocomplete="off"
              style="padding-left: 72px"
            />
          </div>
          <a id="wa_submit_btn" class="submit-btn">SUBMIT</a>
        </div>
      </div>
      <div class="ipl-text page-text">
        <div class="ipl-desc">
          <h2>How to register at Fun88</h2>
          <iframe
            width="560"
            height="450"
            src="https://www.youtube.com/embed/97kdfJyvxtM"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen=""
          ></iframe>
          <h2>IPL 2022 - The Biggest Party is Back!</h2>
          <p>
            With the IPL 2022 just around the corner, cricket enthusiasts all
            over the country are getting copious doses of adrenaline rush.
            According to the new schedule, the 15th edition of the Indian
            Premier- Tata <strong>IPL 2022</strong> will start on 26 March 2022
            in Wankhede Stadium, Mumbai. BCCI has also revealed that the final
            match will be held on 29 May 2022. Set to be held in 4 venues of
            Mumbai and Pune, IPL 2022 will follow the same format as its
            previous editions. The ten teams will be divided into 2 groups, and
            within a group, every team will be pitted against each other twice.
            Group A and Group B teams will play each other once. Each team from
            both groups will play one more match, which will be selected on the
            basis of the draw. To put it in a nutshell, each team will play 14
            league matches. There were 2 qualifiers, 1 eliminator and the Final,
            making it a total of 74 matches.
          </p>
          <h2>Why is IPL Betting Bettor's Number 1 choice?</h2>
          <p>
            In T20 tournaments, the bright side of betting is either winning or
            losing. Punters show excitement towards this format as none of the
            matches would ever end up in a draw, which makes them earn good
            returns if their predictions are right.
          </p>
          <p>
            IPL fans and seasoned cricket bettors know that this popular game
            can be played for cash. Online IPL betting gives a fair and square
            opportunity to earn money while you root for your favorite team and
            player. IPL Betting Tips and Predictions are available on Fun88 —
            the best IPL Satta platform for bettors.
          </p>
          <h2>IPL Teams 2022</h2>
          <p>
            Let's look at which teams will be locking horns with each other this
            IPL 2022:
          </p>
          <table border="0" cellspacing="0" cellpadding="0" class="table-team" style="width: 65%;">
            <tbody>
              <tr>
                <td>Chennai Super Kings</td>
                <td>Kolkata Knight Riders</td>
              </tr>
              <tr>
                <td>Delhi Capitals</td>
                <td>Mumbai Indians</td>
              </tr>
              <tr>
                <td>Sunrisers Hyderabad</td>
                <td>Rajasthan Royals</td>
              </tr>
              <tr>
                <td>Kings Xl Punjab</td>
                <td>Lucknow Supergiants</td>
              </tr>
              <tr>
                <td>Royal Challengers Bangalore</td>
                <td>Gujarat Titans</td>
              </tr>
            </tbody>
          </table>
          <p>
            With the addition of two new teams, Lucknow Supergiants and Gujarat
            Titans, the thrill of the IPL is bound to go notches higher!
          </p>
          <h2>IPL Winner History</h2>
          <p>
            Below is the Indian Premier League winner's list. We're now awaiting
            which team will win the IPL 2022 title.
          </p>
          <table class="table-league" style="width: 80%">
            <tbody>
              <tr>
                <th>IPL Season</th>
                <th>Year</th>
                <th>Winner Team</th>
              </tr>
              <tr>
                <td>14</td>
                <td>2021</td>
                <td>Chennai Super Kings</td>
              </tr>
              <tr>
                <td>13</td>
                <td>2020</td>
                <td>Mumbai Indians</td>
              </tr>
              <tr>
                <td>12</td>
                <td>2019</td>
                <td>Mumbai Indians</td>
              </tr>
              <tr>
                <td>11</td>
                <td>2018</td>
                <td>Chennai Super Kings</td>
              </tr>
              <tr>
                <td>10</td>
                <td>2017</td>
                <td>Mumbai Indians</td>
              </tr>
              <tr>
                <td>09</td>
                <td>2016</td>
                <td>Sunrisers Hyderabad</td>
              </tr>
              <tr>
                <td>08</td>
                <td>2015</td>
                <td>Mumbai Indians</td>
              </tr>
              <tr>
                <td>07</td>
                <td>2014</td>
                <td>Kolkata Knight Riders</td>
              </tr>
              <tr>
                <td>06</td>
                <td>2013</td>
                <td>Mumbai Indians</td>
              </tr>
              <tr>
                <td>05</td>
                <td>2012</td>
                <td>Kolkata Knight Riders</td>
              </tr>
              <tr>
                <td>04</td>
                <td>2011</td>
                <td>Chennai Super Kings</td>
              </tr>
              <tr>
                <td>03</td>
                <td>2010</td>
                <td>Chennai Super Kings</td>
              </tr>
              <tr>
                <td>02</td>
                <td>2009</td>
                <td>Deccan Chargers</td>
              </tr>
              <tr>
                <td>01</td>
                <td>2008</td>
                <td>Rajasthan Royals</td>
              </tr>
            </tbody>
          </table>
          <h2>IPL 2022 Final Players List</h2>
          <div class="player-list">
            <div>
              <p class="player-heading">Mumbai Indians (MI)</p>
              <p>
                Rohit Sharma (C), Anmolpreet Singh, Dewald Brevis, Rahul Buddhi,
                Suryakumar Yadav, Aryan Juyal, Ishan Kishan, Arjun Tendulkar,
                Daniel Sams, Fabian Allen, Hrithik Shokeen, Jofra Archer, Kieron
                Pollard, Arshad Khan, Tilak Varma, Ramandeep Singh, Sanjay
                Yadav, Tim David, Basil Thampi, Jasprit Bumrah, Jaydev Unadkat,
                Mayank Markande, Murugan Ashwin, Riley Meredith, Tymal Mills
              </p>
            </div>
            <div>
              <p class="player-heading">Delhi Capitals (DC)</p>
              <p>
                Rishabh Pant (C), Ashwin Hebbar, David Warner, Mandeep Singh,
                Prithvi Shaw, Rovman Powell, K.S Bharat, Tim Seifert, Axar
                Patel, Kamlesh Nagarkoti, Lalit Yadav, Mitchell Marsh, Pravin
                Dubey, Ripal Patel, Sarfaraz Khan, Vicky Ostwal, Yash Dhull,
                Anrich Nortje, Chetan Sakariya, Kuldeep Yadav, Lungi Ngidi,
                Mustafizur Rahman, Shardul Thakur, Khaleel Ahmed
              </p>
            </div>
            <div>
              <p class="player-heading">Kolkata Knight Riders (KKR)</p>
              <p>
                Shreyas Iyer (C), Ajinkya Rahane, Rinku Singh, Alex Hales,
                Abhijeet Tomar, Ramesh Kumar, Pratham Singh, Sam Billings,
                Sheldon Jackson, Baba Indrajith, Pat Cummins, Mohammad Nabi,
                Nitish Rana, Shivam Mavi, Anukul Roy, Chamika Karunaratne, Aman
                Khan, Andre Russell, Venkatesh Iyer, Umesh Yadav, Rasikh Dar,
                Tim Southee, Ashok Sharma, Sunil Narine, Varun Chakaravarthy
              </p>
            </div>
            <div>
              <p class="player-heading">Sunrisers Hyderabad (SRH)</p>
              <p>
                Kane Williamson (C), Abdul Samad, Aiden Markram, Priyam Garg,
                Ravikumar Samarth, Rahul Tripathi, Umran Malik, Glenn Phillips,
                Nicholas Pooran, Vishnu Vinod, Abhishek Sharma, Marco Jansen,
                Romario Shepherd, Shashank Singh, Washington Sundar, Bhuvneshwar
                Kumar, Fazalhaq Farooqi, Jagadeesha Suchith, Kartik Tyagi,
                Saurabh Dubey, Sean Abbott, Shreyas Gopal, T Natarajan
              </p>
            </div>
            <div>
              <p class="player-heading">Punjab Kings (PBKS)</p>
              <p>
                Mayank Agarwal (C), Bhanuka Rajapaksa, Shikhar Dhawan, Jitesh
                Sharma, Jonny Bairstow, Prabhsimran Singh, Ansh Patel, Atharva
                Taide, Benny Howell, Harpreet Brar, Liam Livingstone, Odean
                Smith, Prerak Mankad, Raj Angad Bawa, Rishi Dhawan, Shahrukh
                Khan, Writtick Chatterjee, Arshdeep Singh, Baltej Dhanda, Ishan
                Porel, Kagiso Rabada, Nathan Ellis, Rahul Chahar, Sandeep
                Sharma, Vaibhav Arora
              </p>
            </div>
            <div>
              <p class="player-heading">Chennai Super Kings (CSK)</p>
              <p>
                MS Dhoni (C), C Hari Nishaanth, Devon Conway, Robin Uthappa,
                Ruturaj Gaikwad, Subhranshu Senapati, Ambati Rayudu, Narayan
                Jagadeesan, Chris Jordan, Dwaine Pretorius, Dwayne Bravo, K
                Bhagath Varma, Mitchell Santner, Moeen Ali, Rajvardhan
                Hangargekar, Ravindra Jadeja, Shivam Dube, Adam Milne, Deepak
                Chahar, KM Asif, Maheesh Theekshana, Mukesh Choudhary, Prashant
                Solanki, Simarjeet Singh, Tushar Deshpande
              </p>
            </div>
            <div>
              <p class="player-heading">Rajasthan Royals (RR)</p>
              <p>
                Sanju Samson (C), Devdutt Padikkal, Jos Buttler, Karun Nair,
                Rassie Van Der Dussen, Shimron Hetmyer, Yashasvi Jaiswal, Dhruv
                Jurel, Anunay Singh, Daryl Mitchell, James Neesham, Ravichandran
                Ashwin, Riyan Parag, Shubham Garhwal, KC Cariappa, Kuldeep Sen,
                Kuldip Yadav, Nathan Coulter-Nile, Navdeep Saini, Obed McCoy,
                Prasidh Krishna, Tejas Baroka, Trent Boult, Yuzvendra Chahal
              </p>
            </div>
            <div>
              <p class="player-heading">Royal Challengers Bangalore (RCB)</p>
              <p>
                Virat Kohli, Faf du Plessis (C), Finn Allen, Anuj Rawat, Dinesh
                Karthik, Luvnith Sisodia, Aneeshwar Gautam, David Willey, Glenn
                Maxwell, Harshal Patel, Mahipal Lomror, Shahbaz Ahamad, Sherfane
                Rutherford, Suyash S Prabhudessai, Wanindu Hasaranga, Akash
                Deep, Chama Milind, Jason Behrendorff, Josh Hazlewood, Karn
                Sharma, Mohammed Siraj, Siddharth Kaul
              </p>
            </div>
            <div>
              <p class="player-heading">Lucknow Supergiants (LSG)</p>
              <p>
                KL Rahul (C), Evin Lewis, Manan Vohra, Manish Pandey, Quinton de
                Kock, Ayush Badoni, Deepak Hooda, Jason Holder, Krishnappa
                Gowtham, Karan Sharma, Krunal Pandya, Kyle Mayers, Marcus
                Stoinis, Ankit Rajpoot, Avesh Khan, Dushmantha Chameera, Mark
                Wood, Mayank Yadav, Mohsin Khan, Ravi Bishnoi, Shahbaz Nadeem
              </p>
            </div>
            <div>
              <p class="player-heading">Gujarat Titans (GT)</p>
              <p>
                Hardik Pandya (C), Abhinav Sadarangani, David Miller, Jason Roy,
                Shubman Gill, Matthew Wade, Wriddhiman Saha, Sai Sudharsan,
                Darshan Nalkande, Dominic Drakes, Gurkeerat Mann Singh, Jayant
                Yadav, Pradeep Sangwan, Rahul Tewatia, Vijay Shankar, Alzarri
                Joseph, Lockie Ferguson, Mohammad Shami, Noor Ahmad, Sai
                Kishore, Rashid Khan, Varun Aaron, Yash Dayal
              </p>
            </div>
          </div>
        </div>

        <div class="ipl-intro">
          <h2>Exciting IPL Betting Opportunities</h2>
          <p>
            <a href="https://www.fun88.com/in">Fun88</a>, a leading IPL live
            betting website, offers the perfect platform for bettors to place
            bets safely. Punters can make deposits and withdrawals with real
            money! Fun88 ensures a fast payout and fair odds as you enjoy the
            thrilling matches of IPL.
          </p>
          <p>
            We are associated with prominent gaming platforms including Betb2b,
            saba, BTi and Betfair for Sports betting - having sportsbook and
            exchange respectively and Microgaming, PlayTech, Evolution,
            SuperSpade, Ezugi, NetEnt and IDN Poker for Live Casino and Slots
          </p>

          <h2>Why Choose Fun88 for IPL 2022 betting?</h2>
          <p>
            <strong>1. Live streaming:</strong> You get live streaming of each
            match once you sign up.
          </p>
          <p>
            <strong>2. Variety of Odds:</strong> Fun88 is ideal for bet
            prediction as we offer live betting odds for IPL 2022. You will get
            access to team statistics, odds, match-ups, and more features for
            you to place your bet. To get more out of your betting strategy, you
            can place bets on the top batsman, top bowler, top fielder, total
            runs, total wickets, total fours or sixes, total ducks, man of the
            match, highest opening partnership etc. As the odds keep changing,
            you'll need to be quick in taking action- to avoid failed bets.
          </p>
          <p>
            <strong>3. Flexible viewing options:</strong> You can also choose to
            display odds using either fractional, decimal, or American odds
            views, depending on your preferences. However, be fully aware of the
            pay-outs you can expect on your winning bets.
          </p>
          <p>
            <strong>4. Sports Exchange:</strong> Very few betting sites have the
            option of an exciting
            <a href="https://www.fun88.com/in/sports/exchange/"
              >sports exchange</a
            >
            which is like a marketplace where bettors can put up their own odds
            and other players can participate via a variety of fancy odds. You
            can even bet on special events like Who will win the next big boss,
            next elections etc.
          </p>
          <p>
            <strong>5. Variety of payment options:</strong> From net banking and
            wallets to -cryptocurrency, we have you covered. Choose from a
            plethora of safe &amp; secure payment options to make deposits.
          </p>
          <p>
            <strong>6. Easy withdrawals:</strong> Once you complete the rollover
            requirements, you can withdraw your money easily in the bank of your
            choice.
          </p>

          <h2>IPL 2022 Frequently Asked Questions (FAQ)</h2>
          <strong>1. How many matches will each team play in IPL 2022?</strong>
          <p>
            Going by the schedule of the IPL 2022, a total of 74 matches will be
            played within a time span of 64 days. This includes 70 group stage
            matches and 4 playoffs. Each team will play 14 matches each in the
            group stages.
          </p>
          <strong>2. Where to watch the IPL 2022 live on TV?</strong>
          <p>
            You can catch all the action of the IPL 2022 live on Star Sports
            network.
          </p>
          <strong
            >3. Which teams will play the first match of the IPL 2022?</strong
          >
          <p>
            The first match of the IPL 2022 will be played by Chennai Super
            Kings (CSK) and Kolkata Knight Riders (KKR)
          </p>
          <strong>4. Where will the first IPL 2022 match be played?</strong>
          <p>
            The first match of the IPL 2022 will be played at Wankhede Stadium,
            Mumbai, Maharashtra.
          </p>
          <strong>5. When is the final match of the IPL 2022?</strong>
          <p>
            The final match of the IPL 2022 is scheduled for the 29th of May,
            2022.
          </p>
        </div>

        <table class="table-match">
          <tbody>
            <tr>
              <th>Match No</th>
              <th>Match Centre</th>
              <th>Date</th>
              <th>Time (IST)</th>
              <th>Venue</th>
            </tr>
            <tr>
              <td>1</td>
              <td>CSK vs KKR</td>
              <td>March 26, 2022</td>
              <td>7:30 PM</td>
              <td>Wankhede Stadium</td>
            </tr>
            <tr>
              <td>2</td>
              <td>DC vs MI</td>
              <td>March 27, 2022</td>
              <td>3:30 PM</td>
              <td>Brabourne – CCI</td>
            </tr>
            <tr>
              <td>3</td>
              <td>PBKS vs RCB</td>
              <td>March 27, 2022</td>
              <td>7:30 PM</td>
              <td>DY Patil Stadium</td>
            </tr>
            <tr>
              <td>4</td>
              <td>GT vs LSG</td>
              <td>March 28, 2022</td>
              <td>7:30 PM</td>
              <td>Wankhede Stadium</td>
            </tr>
            <tr>
              <td>5</td>
              <td>SRH vs RR</td>
              <td>March 29, 2022</td>
              <td>7:30 PM</td>
              <td>MCA Stadium, Pune</td>
            </tr>
            <tr>
              <td>6</td>
              <td>RCB vs KKR</td>
              <td>March 30, 2022</td>
              <td>7:30 PM</td>
              <td>DY Patil Stadium</td>
            </tr>
            <tr>
              <td>7</td>
              <td>LSG vs CSK</td>
              <td>March 31, 2022</td>
              <td>7:30 PM</td>
              <td>Brabourne – CCI</td>
            </tr>
            <tr>
              <td>8</td>
              <td>KKR vs PBKS</td>
              <td>April 1, 2022</td>
              <td>7:30 PM</td>
              <td>Wankhede Stadium</td>
            </tr>
            <tr>
              <td>9</td>
              <td>MI vs RR</td>
              <td>April 2, 2022</td>
              <td>3:30 PM</td>
              <td>DY Patil Stadium</td>
            </tr>
            <tr>
              <td>10</td>
              <td>GT vs DC</td>
              <td>April 2, 2022</td>
              <td>7:30 PM</td>
              <td>MCA Stadium, Pune</td>
            </tr>
            <tr>
              <td>11</td>
              <td>CSK vs PBKS</td>
              <td>April 3, 2022</td>
              <td>7:30 PM</td>
              <td>Brabourne – CCI</td>
            </tr>
            <tr>
              <td>12</td>
              <td>SRH vs LSG</td>
              <td>April 4, 2022</td>
              <td>7:30 PM</td>
              <td>DY Patil Stadium</td>
            </tr>
            <tr>
              <td>13</td>
              <td>RR vs RCB</td>
              <td>April 5, 2022</td>
              <td>7:30 PM</td>
              <td>Wankhede Stadium</td>
            </tr>
            <tr>
              <td>14</td>
              <td>KKR vs MI</td>
              <td>April 6, 2022</td>
              <td>7:30 PM</td>
              <td>MCA Stadium, Pune</td>
            </tr>
            <tr>
              <td>15</td>
              <td>LSG vs DC</td>
              <td>April 7, 2022</td>
              <td>7:30 PM</td>
              <td>DY Patil Stadium</td>
            </tr>
            <tr>
              <td>16</td>
              <td>PBKS vs GT</td>
              <td>April 8, 2022</td>
              <td>7:30 PM</td>
              <td>Brabourne – CCI</td>
            </tr>
            <tr>
              <td>17</td>
              <td>CSK vs SRH</td>
              <td>April 9, 2022</td>
              <td>3:30 PM</td>
              <td>DY Patil Stadium</td>
            </tr>
            <tr>
              <td>18</td>
              <td>RCB vs MI</td>
              <td>April 9, 2022</td>
              <td>7:30 PM</td>
              <td>MCA Stadium, Pune</td>
            </tr>
            <tr>
              <td>19</td>
              <td>KKR vs DC</td>
              <td>April 10, 2022</td>
              <td>3:30 PM</td>
              <td>Brabourne – CCI</td>
            </tr>
            <tr>
              <td>20</td>
              <td>RR vs LSG</td>
              <td>April 10, 2022</td>
              <td>7:30 PM</td>
              <td>Wankhede Stadium</td>
            </tr>
            <tr>
              <td>21</td>
              <td>SRH vs GT</td>
              <td>April 11, 2022</td>
              <td>7:30 PM</td>
              <td>DY Patil Stadium</td>
            </tr>
            <tr>
              <td>22</td>
              <td>CSK vs RCB</td>
              <td>April 12, 2022</td>
              <td>7:30 PM</td>
              <td>DY Patil Stadium</td>
            </tr>
            <tr>
              <td>23</td>
              <td>MI vs PBKS</td>
              <td>April 13, 2022</td>
              <td>7:30 PM</td>
              <td>MCA Stadium, Pune</td>
            </tr>
            <tr>
              <td>24</td>
              <td>RR vs GT</td>
              <td>April 14, 2022</td>
              <td>7:30 PM</td>
              <td>DY Patil Stadium</td>
            </tr>
            <tr>
              <td>25</td>
              <td>SRH vs KKR</td>
              <td>April 15, 2022</td>
              <td>7:30 PM</td>
              <td>Brabourne – CCI</td>
            </tr>
            <tr>
              <td>26</td>
              <td>MI vs LSG</td>
              <td>April 16, 2022</td>
              <td>3:30 PM</td>
              <td>Brabourne – CCI</td>
            </tr>
            <tr>
              <td>27</td>
              <td>DC vs RCB</td>
              <td>April 16, 2022</td>
              <td>7:30 PM</td>
              <td>Wankhede Stadium</td>
            </tr>
            <tr>
              <td>28</td>
              <td>PBKS vs SRH</td>
              <td>April 17, 2022</td>
              <td>3:30 PM</td>
              <td>Brabourne – CCI</td>
            </tr>
            <tr>
              <td>29</td>
              <td>GT vs CSK</td>
              <td>April 17, 2022</td>
              <td>7:30 PM</td>
              <td>MCA Stadium, Pune</td>
            </tr>
            <tr>
              <td>30</td>
              <td>RR vs KKR</td>
              <td>April 18, 2022</td>
              <td>7:30 PM</td>
              <td>Brabourne – CCI</td>
            </tr>
            <tr>
              <td>31</td>
              <td>LSG vs RCB</td>
              <td>April 19, 2022</td>
              <td>7:30 PM</td>
              <td>DY Patil Stadium</td>
            </tr>
            <tr>
              <td>32</td>
              <td>DC vs PBKS</td>
              <td>April 20, 2022</td>
              <td>7:30 PM</td>
              <td>MCA Stadium, Pune</td>
            </tr>
            <tr>
              <td>33</td>
              <td>MI vs CSK</td>
              <td>April 21, 2022</td>
              <td>7:30 PM</td>
              <td>DY Patil Stadium</td>
            </tr>
            <tr>
              <td>34</td>
              <td>DC vs RR</td>
              <td>April 22, 2022</td>
              <td>7:30 PM</td>
              <td>MCA Stadium, Pune</td>
            </tr>
            <tr>
              <td>35</td>
              <td>KKR vs GJ</td>
              <td>April 23, 2022</td>
              <td>3:30 PM</td>
              <td>DY Patil Stadium</td>
            </tr>
            <tr>
              <td>36</td>
              <td>RCB vs SRH</td>
              <td>April 23, 2022</td>
              <td>7:30 PM</td>
              <td>Brabourne – CCI</td>
            </tr>
            <tr>
              <td>37</td>
              <td>LSG vs MI</td>
              <td>April 24, 2022</td>
              <td>7:30 PM</td>
              <td>Wankhede Stadium</td>
            </tr>
            <tr>
              <td>38</td>
              <td>PBKS vs CSK</td>
              <td>April 25, 2022</td>
              <td>7:30 PM</td>
              <td>Wankhede Stadium</td>
            </tr>
            <tr>
              <td>39</td>
              <td>RCB vs RR</td>
              <td>April 26, 2022</td>
              <td>7:30 PM</td>
              <td>MCA Stadium, Pune</td>
            </tr>
            <tr>
              <td>40</td>
              <td>GT vs SRH</td>
              <td>April 27, 2022</td>
              <td>7:30 PM</td>
              <td>Wankhede Stadium</td>
            </tr>
            <tr>
              <td>41</td>
              <td>DC vs KKR</td>
              <td>April 28, 2022</td>
              <td>7:30 PM</td>
              <td>Wankhede Stadium</td>
            </tr>
            <tr>
              <td>42</td>
              <td>PBKS vs LSG</td>
              <td>April 29, 2022</td>
              <td>7:30 PM</td>
              <td>MCA Stadium, Pune</td>
            </tr>
            <tr>
              <td>43</td>
              <td>GT vs RCB</td>
              <td>April 30, 2022</td>
              <td>3:30 PM</td>
              <td>Brabourne – CCI</td>
            </tr>
            <tr>
              <td>44</td>
              <td>RR vs MI</td>
              <td>April 30, 2022</td>
              <td>7:30 PM</td>
              <td>DY Patil Stadium</td>
            </tr>
            <tr>
              <td>45</td>
              <td>DC vs LSG</td>
              <td>May 1, 2022</td>
              <td>3:30 PM</td>
              <td>Wankhede Stadium</td>
            </tr>
            <tr>
              <td>46</td>
              <td>SRH vs CSK</td>
              <td>May 1, 2022</td>
              <td>7:30 PM</td>
              <td>MCA Stadium, Pune</td>
            </tr>
            <tr>
              <td>47</td>
              <td>KKR vs RR</td>
              <td>May 2, 2022</td>
              <td>7:30 PM</td>
              <td>Wankhede Stadium</td>
            </tr>
            <tr>
              <td>48</td>
              <td>GT vs PBKS</td>
              <td>May 3, 2022</td>
              <td>7:30 PM</td>
              <td>DY Patil Stadium</td>
            </tr>
            <tr>
              <td>49</td>
              <td>RCB vs CSK</td>
              <td>May 4, 2022</td>
              <td>7:30 PM</td>
              <td>MCA Stadium, Pune</td>
            </tr>
            <tr>
              <td>50</td>
              <td>DC vs SRH</td>
              <td>May 5, 2022</td>
              <td>7:30 PM</td>
              <td>Brabourne – CCI</td>
            </tr>
            <tr>
              <td>51</td>
              <td>GT vs MI</td>
              <td>May 6, 2022</td>
              <td>7:30 PM</td>
              <td>Brabourne – CCI</td>
            </tr>
            <tr>
              <td>52</td>
              <td>PBKS vs RR</td>
              <td>May 7, 2022</td>
              <td>3:30 PM</td>
              <td>Wankhede Stadium</td>
            </tr>
            <tr>
              <td>53</td>
              <td>LSG vs KKR</td>
              <td>May 7, 2022</td>
              <td>3:30 PM</td>
              <td>MCA Stadium, Pune</td>
            </tr>
            <tr>
              <td>54</td>
              <td>SRH vs RCB</td>
              <td>May 8, 2022</td>
              <td>3:30 PM</td>
              <td>Wankhede Stadium</td>
            </tr>
            <tr>
              <td>55</td>
              <td>CSK vs DC</td>
              <td>May 8, 2022</td>
              <td>3:30 PM</td>
              <td>DY Patil Stadium</td>
            </tr>
            <tr>
              <td>56</td>
              <td>MI vs KKR</td>
              <td>May 9, 2022</td>
              <td>7:30 PM</td>
              <td>DY Patil Stadium</td>
            </tr>
            <tr>
              <td>57</td>
              <td>LSG vs GT</td>
              <td>May 10, 2022</td>
              <td>7:30 PM</td>
              <td>MCA Stadium, Pune</td>
            </tr>
            <tr>
              <td>58</td>
              <td>RR vs DC</td>
              <td>May 11, 2022</td>
              <td>7:30 PM</td>
              <td>DY Patil Stadium</td>
            </tr>
            <tr>
              <td>59</td>
              <td>CSK vs MI</td>
              <td>May 12, 2022</td>
              <td>7:30 PM</td>
              <td>Wankhede Stadium</td>
            </tr>
            <tr>
              <td>60</td>
              <td>RCB vs PBKS</td>
              <td>May 13, 2022</td>
              <td>7:30 PM</td>
              <td>Brabourne – CCI</td>
            </tr>
            <tr>
              <td>61</td>
              <td>KKR vs SRH</td>
              <td>May 14, 2022</td>
              <td>7:30 PM</td>
              <td>MCA Stadium, Pune</td>
            </tr>
            <tr>
              <td>62</td>
              <td>CSK vs GT</td>
              <td>May 15, 2022</td>
              <td>3:30 PM</td>
              <td>Wankhede Stadium</td>
            </tr>
            <tr>
              <td>63</td>
              <td>LSG vs RR</td>
              <td>May 15, 2022</td>
              <td>7:30 PM</td>
              <td>Brabourne – CCI</td>
            </tr>
            <tr>
              <td>64</td>
              <td>PBKS vs DC</td>
              <td>May 16, 2022</td>
              <td>7:30 PM</td>
              <td>DY Patil Stadium</td>
            </tr>
            <tr>
              <td>65</td>
              <td>MI vs SRH</td>
              <td>May 17, 2022</td>
              <td>7:30 PM</td>
              <td>Wankhede Stadium</td>
            </tr>
            <tr>
              <td>66</td>
              <td>KKR vs LSG</td>
              <td>May 18, 2022</td>
              <td>7:30 PM</td>
              <td>DY Patil Stadium</td>
            </tr>
            <tr>
              <td>67</td>
              <td>RCB vs GT</td>
              <td>May 19, 2022</td>
              <td>7:30 PM</td>
              <td>Wankhede Stadium</td>
            </tr>
            <tr>
              <td>68</td>
              <td>RR vs CSK</td>
              <td>May 20, 2022</td>
              <td>7:30 PM</td>
              <td>Brabourne – CCI</td>
            </tr>
            <tr>
              <td>69</td>
              <td>MI vs DC</td>
              <td>May 21, 2022</td>
              <td>7:30 PM</td>
              <td>Wankhede Stadium</td>
            </tr>
            <tr>
              <td>70</td>
              <td>SRH vs PBKS</td>
              <td>May 22, 2022</td>
              <td>7:30 PM</td>
              <td>Wankhede Stadium</td>
            </tr>
            <tr>
              <td>71</td>
              <td>QUALIFIER 1</td>
              <td>TBD</td>
              <td>7:30 PM</td>
              <td>Ahmedabad</td>
            </tr>
            <tr>
              <td>72</td>
              <td>ELIMINATOR</td>
              <td>TBD</td>
              <td>7:30 PM</td>
              <td>Ahmedabad</td>
            </tr>
            <tr>
              <td>73</td>
              <td>QUALIFIER 2</td>
              <td>TBD</td>
              <td>7:30 PM</td>
              <td>Ahmedabad</td>
            </tr>
            <tr>
              <td>74</td>
              <td>FINAL</td>
              <td>May 29, 2022</td>
              <td>7:30 PM</td>
              <td>Ahmedabad</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="ipl-app">
        <h2>IPL BETTING APP</h2>
        <h5>Best Betting Experience At Your Fingertips</h5>
        <div class="bgdiv">
          <div class="flex-box">
            <img
              src="https://static-web.8ar7dbcwtr.com/knor6u/static/images/home/home_download.png"
            />
            <div class="app-desc">
              <h3>
                Enjoy our brand new mobile app, available both for Android and
                iOS users.
              </h3>
              <p>
                Enjoy more than 10k different sports and more than 100 casino
                games, numerous betting types, best payouts, at the touch of a
                button. You can choose from cricket and football to tennis,
                basketball, and even kabaddi and that too Live! Enjoy poker,
                slot games &amp; more.
              </p>
              <p>
                <strong>Why should you download the IPL Betting App?</strong>
              </p>
              <ol>
                <li>Personalised offers and promotions</li>
                <li>Seamless user experience</li>
                <li>Bet on the go</li>
                <li>Easy login experience</li>
              </ol>
              <a href="/in/mobile-app/">HOW TO DOWNLOAD</a>
            </div>
            <div class="qr-desc">
              <div class="qr-code" id="qr-code">
                <canvas width="120" height="120"></canvas>
              </div>
              <p>
                To download, scan the QR code given above from your mobile or
                open the app download link.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
};
</script>
<style lang="less" scoped>
.mod-ipl {
  width: 100%;
  margin: 0 0;
  padding: 0 0;
  .mod-banner {
    max-width: 1920px;
    width: 100%;
    height: 465px;
    position: relative;
    overflow: hidden;
    margin: 0 auto;
    z-index: 5;
    .slide-wrap {
      position: relative;
      text-align: center;
      width: 100%;
      height: 100%;
      overflow: hidden;
      .num {
        display: none;
        width: 100%;
        position: absolute;
        bottom: 20px;
        left: 0;
        z-index: 10;
      }
    }
  }
  .h4 {
    padding-bottom: 20px;
    font-size: 32px;
    font-weight: bold;
    color: #00a6ff;
    text-align: center;
    word-break: break-word;
  }
  .home-promo {
    padding: 0;
    max-width: 1560px;
    margin: 0 auto;
    margin-top: 50px;
    .slick-slider {
      position: relative;
      display: block;
      box-sizing: border-box;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      -webkit-touch-callout: none;
      -khtml-user-select: none;
      -ms-touch-action: pan-y;
      touch-action: pan-y;
      -webkit-tap-highlight-color: transparent;
      .slick-list {
        padding: 0 !important;
        margin: 0 50px;
        position: relative;
        display: block;
        overflow: hidden;
        .slick-track {
          position: relative;
          top: 0;
          left: 0;
          display: block;
          margin-left: auto;
          margin-right: auto;
          .promo-img {
            width: 457px;
            height: 244px;
            img {
              width: 100%;
              vertical-align: top;
              border: 0;
            }
            .promo-info {
              color: #00a6ff;
              font-size: 14px;
              text-align: center;
              padding: 12px 5px;
              margin: 0;
            }
          }
        }
      }
    }
  }
  .ipl-whatsapp {
    max-width: 800px;
    margin: 0 auto;
    padding: 0 2%;
    text-align: center;
    h5 {
      font-size: 21px;
      font-weight: bold;
      color: #00a6ff;
      text-align: left;
      word-break: break-word;
      padding-bottom: 8px;
      margin-top: 30px;
      max-width: 526px;
      width: 100%;
    }
    div {
      display: flex;
      justify-content: center;
    }
    .submit_btn {
      width: 27%;
      background: #00a6ff;
      height: 65px;
      line-height: 65px;
      font-size: 25px;
      border-radius: 10px;
      color: #fff;
      cursor: pointer;
    }
  }
  .ipl-text {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 2%;
  }
  .page-text {
    max-width: 1400px;
    margin: 0 auto;
    .ipl-desc {
      min-height: 610px;
      text-align: center;
      padding: 0 0;
      margin: 0 0;
      h2 {
        font-size: 32px;
        font-weight: bold;
        color: #00a6ff;
        text-align: center;
        word-break: break-word;
        padding-bottom: 8px;
        margin-top: 30px;
      }
    }
    .ipl-intro {
      margin-top: 40px;
      h2 {
        font-size: 32px;
        font-weight: bold;
        color: #00a6ff;
        text-align: center;
        word-break: break-word;
        padding-bottom: 8px;
        margin-top: 30px;
      }
      p {
        font-size: 18px;
        padding-bottom: 22px;
        word-break: break-word;
        color: #404040;
      }
    }
    table {
      margin: 0 auto;
      text-align: center;
      border: 1px solid #9a9a9a;
      margin-bottom: 15px;
      tr th:not(:last-of-type) {
        border-right: 1px solid #9a9a9a;
        padding: 7px 5px;
        background: #3a74ec;
        color: #fff;
      }
      td {
        width: 22.5%;
      }
    }
  }
  .ipl-app {
    margin-top: 35px;
    color: #fff;
    .bgdiv {
      background: url(../../assets/images/in/home_mobile_bg.png) no-repeat
        center;
      background-size: 100% 570px;
      margin-top: -60px;
      padding: 60px 0;
    }
    h2 {
      font-size: 32px;
      font-weight: bold;
      color: #00a6ff;
      text-align: center;
      word-break: break-word;
      padding-bottom: 8px;
      margin-top: 30px;
    }
    h5 {
      font-size: 22px;
      font-weight: bold;
      color: #323232;
      text-align: center;
    }
    .flex-box {
      max-width: 1200px;
      margin: 25px auto 0;
      padding: 0 2%;
      box-sizing: border-box;
      max-width: 1200px;
      margin: 25px auto 0;
      padding: 0 2%;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: space-between;
      img {
        width: 34%;
        max-width: 410px;
        border-style: none;
        vertical-align: top;
        border: 0;
      }
      .app-desc {
        margin: 0 40px;
        h3 {
          font-size: 18px;
          font-weight: bold;
          margin: 0 0;
          padding: 0 0;
        }
        p {
          padding: 0 0;
          font-size: 12px;
          line-height: 16px;
          margin: 0 0 15px 0;
        }
        ol {
          list-style: decimal;
          margin: 0 0;
          padding: 0 0 0 20px;
          li {
            list-style: inherit;
            font-size: 12px;
          }
        }
        a {
          display: inline-block;
          background: #fff;
          color: #00a6ff;
          border: 1px solid #00a6ff;
          border-radius: 4px;
          padding: 10px 45px;
          margin-top: 20px;
          text-decoration: none;

        }
      }
      .qr-desc {
        margin-top: -70px;
        text-align: center;
        padding: 0;
        margin: 0;
        .qr-code {
          width: 120px;
          height: 120px;
          margin: 0 auto 10px;
          background-color: #fff;
        }
        p{
            font-size: 12px;

        }
      }
    }
  }
}
</style>